import React, { useState } from 'react'
import InnerPageHeader from '../../common/InnerPageHeader'
import Course1 from '../../assets/images/course1.jpg'
import Course2 from '../../assets/images/course2.jpg'
import ProfileApis from '../../queries/profile'
import noDataImg from '../../assets/images/No data-cuate.png'
import { Link } from 'react-router-dom'
import LearningApis from '../../queries/learning'
import CardPagination from '../../common/CardPagination'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
function Learnings() {
    const [currentPage, setcurrentPage] = useState(1)
    const [length, setlength] = useState(10)
    const { data: purchasedCourse, isLoading } = ProfileApis.GetPurchasedCourses(length, currentPage)
    const createCourseHistory = LearningApis.CreateCourseHistory()
    const { t } = useTranslation('learning')
    const [apiCalledOnce, setapiCalledOnce] = useState(false)
    const createHistoryhandler = async (id, taskHistory) => {
        if (taskHistory.length == 0) {
            await createCourseHistory.mutateAsync({ courseId: id })

        }

    }
    return (
        <>
            <InnerPageHeader
                title='My Learnings'
                subtitile='My Account'
                titlePath='my-learnings'
                subTitlePath='my-account'
                t={t}
            />
            <section className="home_category_section">
                <div className="container">
                    {
                        isLoading ?
                            <div className='row'>
                                {
                                    Array.from({ length: 4 }).map((_index) => (
                                        <div className='col-lg-3'>
                                            <div className="course_box">
                                                <Skeleton className='course_box_img' />
                                                <div className="course_box_cnt">
                                                    <div className="course_box_cnt_first_row">
                                                        <span><Skeleton width={45} /></span>
                                                        <span><i className="fa-solid fa-star"></i> <Skeleton width={5} /></span>
                                                    </div>
                                                    <Skeleton className="course_box_cnt_head" />
                                                    <p><Skeleton count={3} /></p>
                                                    <div className="course_box_cnt_footer">
                                                        <Skeleton className="rate" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                }
                            </div>
                            :
                            <div className="row mt-3">
                                {
                                    purchasedCourse?.data?.data.length > 0 ?
                                        purchasedCourse?.data?.data.map((item, index) => (
                                            <div className="col-md-3">
                                                <Link to={`/my-learnings/${item?.id}`}
                                                // onClick={() => createHistoryhandler(item.id, item.task_histories)}
                                                >
                                                    <div className="course_box">
                                                        <div className="course_box_img">
                                                            <img src={item.image ?? Course1} alt="" />
                                                        </div>
                                                        <div className="course_box_cnt">
                                                            <div className="course_box_cnt_first_row">
                                                                <span>{item?.modules} {t('Lesson')}</span>
                                                                <span>
                                                                    <i className="fa-solid fa-star"></i> {item.rating}
                                                                </span>
                                                            </div>
                                                            <div className="course_box_cnt_head">
                                                                {item?.title}
                                                            </div>
                                                            {
                                                                item.description.length > 100 ?
                                                                    <p>
                                                                        {`${item.description.slice(0, 100)}.......`} <span style={{ color: '#09aabb' }}>
                                                                            Read more
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    <p>{item.description}</p>
                                                            }
                                                            {/* <div className="course_box_cnt_footer">
                                                     <span>Purchased on - 12-05-2024</span>
                                                 </div> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))

                                        : <div className='col-md-12'>
                                            <center>
                                                <img src={noDataImg} className='w-25' />
                                                <span>{t('No data found')}</span>
                                            </center>
                                        </div>
                                }
                                {
                                    purchasedCourse?.data?.totalCount > 0 &&
                                    <CardPagination
                                        length={length}
                                        page={currentPage}
                                        pageChangehandler={setcurrentPage}
                                        totalEntry={purchasedCourse?.data?.totalCount}
                                    />
                                }


                            </div>

                    }

                </div>
            </section>
        </>


    )
}

export default Learnings
import React from 'react'
import SingleView from '../../components/Student/Learnings/SingleView'
import { useParams } from 'react-router-dom'
import LearningApis from '../../queries/learning'
import { useTranslation } from 'react-i18next'
function LearningsSingleView() {
    const { id } = useParams()
    const { t } = useTranslation('learning')
    const { data: learningData, refetch: refetchLearningData,isLoading:apiLoading } = LearningApis.GetLearningVideo(id)
    const { data: learningNotes } = LearningApis.GetLearningNotes(id)
    return (
        <SingleView
            learningData={learningData}
            refetchLearningData={refetchLearningData}
            learningNotes={learningNotes}
            courseId={id}
            apiLoading={apiLoading}
            t={t}
        />
    )
}

export default LearningsSingleView
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
function Categories({ categoriesList, t, catApiLoading }) {
    const navigate = useNavigate()
    const navigateHandler = (catId) => {
        navigate('/courses', { state: { category: catId } })
    }

    return (

        <section className="home_category_section">
            <div className="container">
                <div className="main_heading_txt">
                    <div className="row align-items-center">
                        <div className="col-md-6" data-aos="fade-up">
                            <h2>
                                {t("Explore Our")}<br />
                                <strong>{t("Popular Categories")}</strong>
                            </h2>
                        </div>
                        <div className="col-md-6">
                            <p>
                                {t("Discover a world of knowledge and opportunities with our online education platform pursue a new career")}.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    {
                        catApiLoading ?
                            Array.from({ length: 4 }).map((_, index) => (
                                <div className="col-md-3">
                                    <div className="category_box">
                                        <div className="category_box_ico"><Skeleton circle={true} width={50} height={50} /></div>
                                        <div className="category_box_cnt_skeleton">
                                            <h3><Skeleton /></h3>
                                            <span><Skeleton /></span>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            categoriesList?.data &&
                            categoriesList?.data.map((item, index) => {
                                return (
                                    <div className="col-md-3" data-aos={index % 2 === 0 ? "fade-down" : "fade-up"}>
                                        <a href="#">
                                            <div className="category_box" onClick={() => navigateHandler(item.id)}>
                                                <div className="category_box_ico"><i className={item.icon ?? 'fa-solid fa-chart-simple'}></i></div>
                                                <div className="category_box_cnt">
                                                    <strong>{item.name}</strong>
                                                    <span>{item.description}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                    }

                </div>
            </div>
            <div className="viewAllBtn" data-aos="fade-down">
                <Link to='/categories'>{t("View All Categories")} <i class="fa-solid fa-arrow-right"></i></Link>
            </div>
        </section>
    )
}

export default Categories
import React from 'react'
import Skeleton from 'react-loading-skeleton'
function CourseInstructorDetails({ learningData, t, apiLoading }) {
    return (
        <div className="col-md-4">
            <div className="course_dtl_level_box p-4">
                <h4>{t("Instructor")}</h4>
                <div className="reviewViewRow align-items-center border-0">
                    <div className="reviewViewImg"></div>
                    <div className="reviewViewCnt">
                        <h5>{apiLoading ? <Skeleton /> : learningData?.data?.videos?.tutor?.user_detail.firstName + " " + learningData?.data?.videos?.tutor?.user_detail?.lastName}</h5>
                        <p>{apiLoading ? <Skeleton /> : learningData?.data?.videos?.tutor?.email}</p>
                    </div>
                </div>

                <p>
                    {apiLoading ? <Skeleton /> : learningData?.data?.videos?.tutor?.about}
                </p>
                {
                    apiLoading ?
                        <div className="skeleton-circles-container">
                            {Array(3).fill(null).map((_, index) => (
                                <div key={index} className="skeleton-item">
                                    <Skeleton circle={true} width={30} height={30} />
                                </div>
                            ))}
                        </div>
                        :
                        <div className="social_sec">
                            <a href={learningData?.data?.videos?.tutor?.user_detail?.facebook} className="social_sec__ico">
                                <i className="fa-brands fa-facebook"></i></a>
                            <a href={learningData?.data?.videos?.tutor?.user_detail?.twitter} className="social_sec__ico">
                                <i className="fa-brands fa-x-twitter"></i></a>
                            <a href={learningData?.data?.videos?.tutor?.user_detail?.linkedin} className="social_sec__ico">
                                <i className="fa-brands fa-linkedin-in"></i></a>
                        </div>
                }

            </div>
        </div>

    )
}

export default CourseInstructorDetails